import {PrivacyPolicy} from "../../domain/entities/privacy-policy.entity";
import {createFeature, createReducer, on} from "@ngrx/store";
import {privacypolicyActions } from "./../actions/privacy-policy.actions";

export interface PrivacyPolicyState {
    policies: PrivacyPolicy[];
    loading: boolean;
    error: any;
}

export const initialState: PrivacyPolicyState = {
    policies: [],
    loading: false,
    error: null,
};

const privacyPoliciesFeature = createFeature({
    name: 'privacyPolicy',
    reducer: createReducer(
        initialState,
        on(privacypolicyActions.loadPrivacyPolicies, (state: PrivacyPolicyState): PrivacyPolicyState => ({
            ...state,
            loading: true,
        })),
        on(privacypolicyActions.loadPrivacyPolicySuccess, (state: PrivacyPolicyState, {policies}): PrivacyPolicyState => ({
            ...state,
            policies,
            loading: false,
        })),
        on(privacypolicyActions.loadPrivacyPolicyFailure, (state: PrivacyPolicyState, {error}): PrivacyPolicyState => ({
            ...state,
            error,
            loading: false,
        })),

    )
})


export const {
    name: privacyPolicyFeatureKey,
    reducer: privacyPolicyReducer,
    selectPolicies,
    selectLoading,
    selectError,
} = privacyPoliciesFeature;