import { createAction, props } from "@ngrx/store";
import { FaqCategory } from "../../domain/entities/faqs.entity";


export const loadFaqs = createAction('[FaqCategory] Load Faqs');

export const loadFaqsSuccess = createAction(
    '[FaqCategory] Load Faqs Success',
    props<{ categories: FaqCategory[] }>()
);

export const loadFaqsFailure = createAction(
    '[FaqCategory] Load Faqs Failure',
    props<{ error: any }>()
);